define("zerorisk-frontend/mirage/models/vulnerability", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberCliMirage.Model.extend({
    assetIp: (0, _emberCliMirage.belongsTo)('asset-ip'),
    scanIp: (0, _emberCliMirage.belongsTo)('scan-ip')
  });
  _exports.default = _default;
});