define("zerorisk-frontend/mixins/debounce-query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEBOUCE_MS = 500;

  /**
  Inject this mixin in a route-driven controller in order to be able
  to update query params with a debounce.
  @class DebounceQueryParams
  */
  var _default = Ember.Mixin.create({
    debounceMs: DEBOUCE_MS,
    /**
    Setup the controller for debouncing query param by calling
    setupDebounceQueryParams
    @method init
    */
    init: function init() {
      this._super();
      this.setupDebounceQueryParams();
    },
    /**
    Update the underneath property hence the query param
    @method updateQueryParam
    @param param {String} the name of the property to update
    @param value {String} the new value of the property to update
    */
    updateQueryParam: function updateQueryParam(param, value) {
      this.set(param, value);
    },
    /**
    Decounce the query param update
    @method paramUpdate
    @param context {Object} the name of the property to update
    @param debounceParamName {String} the name of the property to update
    in the format debounce_paramName
    */
    paramUpdate: function paramUpdate(context, debounceParamName) {
      var originalParamName = debounceParamName.substr(9, debounceParamName.length);
      var originalParamValue = this.get(debounceParamName);
      Ember.run.debounce(this, this.updateQueryParam, originalParamName, originalParamValue, this.get("debounceQueryParams.".concat(originalParamName)));
    },
    /**
    Create an observer for each of the properties in the debounceQueryParams obejct
    @method setupDebounceQueryParams
    */
    setupDebounceQueryParams: function setupDebounceQueryParams() {
      var _this = this;
      var debounceQueryParams = this.debounceQueryParams;
      var _loop = function _loop(param) {
        (true && !("".concat(param, " must be in queryParams")) && Ember.assert(_this.get(param) != null, "".concat(param, " must be in queryParams")));
        Ember.run.schedule('actions', _this, function () {
          this.set("debounce_".concat(param), this.get(param));
          this.addObserver("debounce_".concat(param), this, 'paramUpdate');
        });
      };
      for (var param in debounceQueryParams) {
        _loop(param);
      }
    }
  });
  _exports.default = _default;
});