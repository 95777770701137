define("zerorisk-frontend/mirage/factories/file", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberCliMirage.Factory.extend({
    entity: function entity() {
      return _faker.default.random.number(10);
    },
    reference: function reference() {
      return _faker.default.random.number();
    },
    type: function type() {
      return genType();
    },
    name: function name() {
      return _faker.default.system.fileName();
    },
    description: function description() {
      return _faker.default.random.words();
    },
    visible: function visible() {
      return _faker.default.random.boolean();
    },
    created: function created() {
      return Date.now();
    },
    createdBy: function createdBy() {
      return _faker.default.name.findName();
    }
  });
  _exports.default = _default;
  function genType() {
    var types = ["POLICY", "STANDARD", "ROC", "AOC", "OTHER", "DIAGRAM"];
    return types[Math.floor(Math.random() * types.length)];
  }
});