define("zerorisk-frontend/mirage/factories/scan", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var scans = [{
    status: 'FINISHED',
    scanEnd: Date.now() - 9900000
  }, {
    status: 'FINISHED',
    scanEnd: Date.now() - 1000000
  }, {
    status: 'RUNNING',
    scanEnd: null
  }, {
    status: 'RUNNING',
    scanEnd: null
  }];
  var _default = _emberCliMirage.Factory.extend({
    scanStart: Date.now(),
    scanEnd: function scanEnd(i) {
      return scans[i].scanEnd;
    },
    scanReference: Date.now().toString(),
    status: function status(i) {
      return scans[i].status;
    },
    riskAverage: 1,
    riskCoverage: 1.1,
    totalVulnerabilities: 5,
    totalPCIVulnerabilities: _emberCliMirage.faker.list.random(0, 1, 2, 3),
    scanNextStart: null,
    quarterly: false,
    scanTitle: function scanTitle(i) {
      return _emberCliMirage.faker.random.word();
    },
    asvProvider: "QUALYS"
  });
  _exports.default = _default;
});