define("zerorisk-frontend/mirage/factories/rss", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-cli-mirage"], function (_exports, _defineProperty2, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberCliMirage.Factory.extend({
    news: function news() {
      var _ref, _ref2;
      return {
        pcisecuritystandards: {
          item: [{
            link: "https://seginfo.com.br/2017/08/10/ransomware-por-carlos-caetano/",
            description: "",
            id: "3164fe298f8613557da1a2cc8ca052a8",
            title: "Ransomware â€“ Por Carlos Caetano",
            pubDate: "Thu, 10 Aug 2017 09:20:56 -0500"
          }, {
            link: "http://computerworld.com.br/empresas-no-brasil-ainda-negligenciam-norma-de-seguranca-para-pagamentos",
            description: "",
            id: "69dc851e72680b93c83cbf1a241e2627",
            title: "Empresas no Brasil ainda negligenciam norma de seguranÃ§a para pagamentos",
            pubDate: "Tue, 08 Aug 2017 09:20:56 -0500"
          }, {
            link: "https://www.adyen.com/pt_BR/blog-br/os-beneficios-de-seguranca-das-regras-pci-dss",
            description: "",
            id: "fad7d5beb0f9dc79c10df9e976496520",
            title: "Os benefÃ­cios de seguranÃ§a das regras PCI DSS",
            pubDate: "Tue, 08 Aug 2017 09:20:56 -0500"
          }]
        },
        advantio: {
          item: [{
            author: "igor.mancini@ntt.ie (Igor Mancini)",
            date: "2016-06-24T08:47:06Z",
            link: "https://blog.advantio.com/lastpass-work-anywhere-security-risks",
            description: "Long gone are the days where entire workforces would need to be in the same office just to work together. Organisations can now collaborate no matter where they are based â€“ and weâ€™re not just talking about having a network of offices all over the world either.",
            guid: "https://blog.advantio.com/lastpass-work-anywhere-security-risks",
            title: "'A work anywhere' Information Security Policy to reduce Security Risks",
            content: "Long gone are the days where entire workforces would need to be in the same office just to work together. Organisations can now collaborate no matter where they are based â€“ and weâ€™re not just talking about having a network of offices all over the world either.",
            category: "News, Tools and Apps",
            pubDate: "Fri, 24 Jun 2016 08:47:06 GMT"
          }, {
            author: "mb@ntt.ie (Marco Borza)",
            date: "2016-06-10T09:41:58Z",
            link: "https://blog.advantio.com/pos-point-pf-sales-breaches-on-the-raise",
            description: "I recently read an article on the PointofSales Blog",
            guid: "https://blog.advantio.com/pos-point-pf-sales-breaches-on-the-raise",
            title: "Payment Card-Reading devices and terminals breaches on the rise",
            content: "Experts have found <em>that smartphone and tablet-based mobile point-of sale terminals will take on a significant role in businesses, handling 40% of all retail transaction value by 2021, up from an expected 12% in 2016</em>. This made me think about the importance of the PoS and other payment card-reading devices.",
            category: ["Compliance", "Zerorisk Solutions"],
            pubDate: "Fri, 10 Jun 2016 09:41:58 GMT"
          }, {
            author: "igor.mancini@ntt.ie (Igor Mancini)",
            date: "2016-05-27T12:01:40Z",
            link: "https://blog.advantio.com/payment-cards-contactless-method-2025",
            description: "People buy products and services for various reasons and needs, and even if everyone has a different taste&nbsp;and seems so different, they have at least two things in common when they buy something: the payment methods they use to pay those goods and the fear they experience to get their payment cards and money stolen.",
            guid: "https://blog.advantio.com/payment-cards-contactless-method-2025",
            title: "Payment Cards and Contactless will be the most used Payment Methods",
            content: "People buy products and services for various reasons and needs, and even if everyone has a different taste&nbsp;and seems so different, they have at least two things in common when they buy something: the payment methods they use to pay those goods and the fear they experience to get their payment cards and money stolen.",
            category: ["Compliance", "Zerorisk Solutions"],
            pubDate: "Fri, 27 May 2016 12:01:40 GMT"
          }]
        },
        visaeurope: {
          item: [{
            link: "/newsroom/news/visa-expands-global-transaction-processing-with-facilities-in-singapore-and-united-kingdom",
            title: "Global transaction processing expanded",
            pubDate: "2017-07-26T12:29:04"
          }, {
            link: "/newsroom/news/paypal-partnership-extended-to-europe",
            title: "PayPal partnership extended to Europe",
            pubDate: "2017-07-19T15:30:11"
          }, {
            link: "/newsroom/news/visa-commits-to-strategic-investment-in-klarna-companies-plan-partnership-deal",
            title: "Visa Commits to Strategic Investment in Klarna",
            pubDate: "2017-06-27T15:40:20"
          }]
        },
        mastercard: {
          item: [(_ref = {
            comments: 0
          }, (0, _defineProperty2.default)(_ref, "comments", "https://newsroom.mastercard.com/press-releases/mastercard-and-apple-music-press-play-on-priceless-cities-soundtracks/#comments"), (0, _defineProperty2.default)(_ref, "link", "https://newsroom.mastercard.com/press-releases/mastercard-and-apple-music-press-play-on-priceless-cities-soundtracks/"), (0, _defineProperty2.default)(_ref, "author", "Lauren Mostowyk"), (0, _defineProperty2.default)(_ref, "guid", {
            isPermaLink: false,
            content: "https://newsroom.mastercard.com/?post_type=press-releases&#038;p=60580"
          }), (0, _defineProperty2.default)(_ref, "description", "Canadian artists curate playlists tied to one-of-a-kind experiences in Toronto and Montreal TORONTO Aug. 15, 2017 Today Mastercard Priceless Cities is turning up the sounds of summer by announcing a new program with Apple Music."), (0, _defineProperty2.default)(_ref, "title", "Mastercard and Apple Music press play on Priceless Cities soundtracks"), (0, _defineProperty2.default)(_ref, "category", ["Uncategorized", "apple", "music", "Priceless", "priceless cities"]), (0, _defineProperty2.default)(_ref, "content", "Canadian artists curate playlists tied to one-of-a-kind experiences in Toronto and Montreal"), (0, _defineProperty2.default)(_ref, "commentRss", "https://newsroom.mastercard.com/press-releases/mastercard-and-apple-music-press-play-on-priceless-cities-soundtracks/feed/"), (0, _defineProperty2.default)(_ref, "pubDate", "Tue, 15 Aug 2017 14:00:57 +0000"), _ref), (_ref2 = {
            comments: 0
          }, (0, _defineProperty2.default)(_ref2, "comments", "https://newsroom.mastercard.com/press-releases/explorez-lunivers-musical-priceless-cities-grace-a-mastercard-et-a-apple-music/#comments"), (0, _defineProperty2.default)(_ref2, "link", "https://newsroom.mastercard.com/press-releases/explorez-lunivers-musical-priceless-cities-grace-a-mastercard-et-a-apple-music/"), (0, _defineProperty2.default)(_ref2, "author", "Lauren Mostowyk"), (0, _defineProperty2.default)(_ref2, "guid", {
            isPermaLink: false,
            content: "https://newsroom.mastercard.com/?post_type=press-releases&#038;p=60585"
          }), (0, _defineProperty2.default)(_ref2, "description", "Des artistes canadiens concoctent des listes de lecture exceptionnelles qui agrÃ©mentent les expÃ©riences torontoise et montrÃ©alaise TORONTO â€“"), (0, _defineProperty2.default)(_ref2, "title", "Explorez musical Priceless Cities Mastercard et  Apple Music"), (0, _defineProperty2.default)(_ref2, "content", "Des artistes canadiens concoctent des listes de lecture exceptionnelles qui agrÃ©mentent les expÃ©riences torontoise et montrÃ©alaise"), (0, _defineProperty2.default)(_ref2, "commentRss", "https://newsroom.mastercard.com/press-releases/explorez-lunivers-musical-priceless-cities-grace-a-mastercard-et-a-apple-music/feed/"), (0, _defineProperty2.default)(_ref2, "pubDate", "Tue, 15 Aug 2017 13:59:43 +0000"), _ref2)]
        }
      };
    }
  });
  _exports.default = _default;
});