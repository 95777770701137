define("zerorisk-frontend/mirage/factories/saq-question", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberCliMirage.Factory.extend({
    text: function text() {
      return _emberCliMirage.faker.lorem.sentence();
    },
    type: function type(i) {
      return _emberCliMirage.faker.list.random('YES_NO', 'NONE')(i);
    },
    milestone: 'Build and Maintain a Secure Network',
    code: function code(i) {
      if (this.section === 'Requirement 1:  Install and maintain a firewall configuration to protect data') return "1.".concat(i);
      if (this.section === 'Requirement 2: Do not use vendor-supplied defaults for system passwords and other security parameters') return "2.".concat(i);
      if (this.section === 'Requirement 3:  Protect stored cardholder data') return "3.".concat(i);
    }
  });
  _exports.default = _default;
});