define("zerorisk-frontend/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(server) {
    // This data will not be loaded in your tests.
    // Entity tree:
    /*
        2
        |- 3
           |- 5
           |- 6
           |- 7
              | - 8
              | - 9
        | - 4
            | - 10
    */

    var entity2 = server.create('entity', {
      id: 11,
      childrenId: [3, 4]
    });
    var entity1 = server.create('entity', {
      id: 1,
      childrenId: [3, 4]
    });
    // server.create('contact-info', { entity2 });

    var entity3 = server.create('entity', {
      id: 3,
      parentId: [entity2.id, entity1.id],
      childrenId: [5, 6, 7]
    });
    var entity5 = server.create('entity', {
      id: 5,
      parentId: entity3.id
    });
    var entity6 = server.create('entity', {
      id: 6,
      parentId: entity3.id
    });
    var entity7 = server.create('entity', {
      id: 7,
      parentId: entity3.id,
      childrenId: [8, 9]
    });
    var entity8 = server.create('entity', {
      id: 8,
      parentId: entity7.id
    });
    var entity9 = server.create('entity', {
      id: 9,
      parentId: entity7.id
    });
    var entity4 = server.create('entity', {
      id: 4,
      parentId: [entity2.id, entity1.id],
      childrenId: [10]
    });
    var entity10 = server.create('entity', {
      id: 10,
      parentId: entity4.id
    });
    var user1 = server.create('user', {
      entities: [entity7]
    });
    var user2 = server.create('user', {
      entities: [entity2, entity1]
    });
    var saqA = server.create('saq', {
      id: 4,
      entityId: entity2.id,
      type: 'SAQ_A',
      version: '3.2',
      status: 'draft'
    });
    var section9 = 'Requirement 9:  Restrict physical access to cardholder data';
    var q95 = server.create('saq-question', {
      id: 920,
      saqId: saqA.id,
      section: section9,
      code: '9.5',
      text: "Are all media physically secured (including but not limited to computers, removable electronic media, paper receipts, paper reports, and faxes)? <br>For purposes of Requirement 9, <b>media</b> refers to all paper and electronic media containing cardholder data.",
      testing: "<ul><li>Review policies and procedures for physically securing media</li><li>Interview personnel</li></ul>",
      guidance: "Without physical access controls, such as badge systems and door controls, unauthorized persons could potentially gain access to the facility to steal, disable, disrupt, or destroy critical systems and cardholder data.<br>Locking console login screens prevents unauthorized persons from gaining access to sensitive information, altering system configurations, introducing vulnerabilities into the network, or destroying records."
    });
    // server.create('saq-answer', { saqId: saqA.id, questionId: q95.id, type: 'NOT_APPLICABLE' });
    var q9sa = server.create('saq-question', {
      id: 925,
      saqId: saqA.id,
      section: section9,
      code: '9.6.A',
      text: "Is strict control maintained over the internal or external distribution of any kind of media?"
    });
    // server.create('saq-answer', { saqId: saqA.id, questionId: q9sa.id, type: 'NOT_APPLICABLE' });
    var q961 = server.create('saq-question', {
      id: 926,
      saqId: saqA.id,
      section: section9,
      code: '9.6.1',
      text: "Is media classified so the sensitivity of the data can be determined?"
    });
    // server.create('saq-answer', { saqId: saqA.id, questionId: q961.id, type: 'NOT_APPLICABLE' });
    var q962 = server.create('saq-question', {
      id: 923,
      saqId: saqA.id,
      section: section9,
      code: '9.6.2',
      text: "Is media sent by secured courier or other delivery method that can be accurately tracked?"
    });
    // server.create('saq-answer', { saqId: saqA.id, questionId: q962.id, type: 'NOT_APPLICABLE' });
    var q963 = server.create('saq-question', {
      id: 935,
      saqId: saqA.id,
      section: section9,
      code: '9.6.3',
      text: "Is management approval obtained prior to moving the media (especially when media is distributed to individuals)?"
    });
    // server.create('saq-answer', { saqId: saqA.id, questionId: q963.id, type: 'NOT_APPLICABLE' });
    var q97 = server.create('saq-question', {
      id: 927,
      saqId: saqA.id,
      section: section9,
      code: '9.7',
      text: "Is strict control maintained over the storage and accessibility of media?"
    });
    // server.create('saq-answer', { saqId: saqA.id, questionId: q97.id, type: 'NOT_APPLICABLE' });
    var q98a = server.create('saq-question', {
      id: 931,
      saqId: saqA.id,
      section: section9,
      code: '9.8.A',
      text: "Is all media destroyed when it is no longer needed for business or legal reasons?"
    });
    // server.create('saq-answer', { saqId: saqA.id, questionId: q98a.id, type: 'NOT_APPLICABLE' });
    var q981a = server.create('saq-question', {
      id: 934,
      saqId: saqA.id,
      section: section9,
      code: '9.8.1.A',
      text: "Are hardcopy materials cross-cut shredded, incinerated, or pulped so that cardholder data cannot be reconstructed?"
    });
    // server.create('saq-answer', { saqId: saqA.id, questionId: q981a.id, type: 'NOT_APPLICABLE' });
    var q981b = server.create('saq-question', {
      id: 1235,
      saqId: saqA.id,
      section: section9,
      code: '9.8.1.B',
      text: "Are storage containers used for materials that contain information to be destroyed secured to prevent access to the contents?"
    });
    // server.create('saq-answer', { saqId: saqA.id, questionId: q981b.id, type: 'NOT_APPLICABLE' });
    var section12 = 'Requirement 12:  Maintain a policy that addresses information security for all personnel';
    var q1281 = server.create('saq-question', {
      id: 928,
      saqId: saqA.id,
      section: section12,
      code: '12.8.1',
      text: "Is a list of service providers maintained?"
    });
    var q1282 = server.create('saq-question', {
      saqId: saqA.id,
      section: section12,
      code: '12.8.2',
      text: "Is a written agreement maintained that includes an acknowledgement that the service providers are responsible for the security of cardholder data the service providers possess or otherwise store, process, or transmit on behalf of the customer, or to the extent that they could impact the security of the customer cardholder data environment?<br><br><i><b>Note</b></i>: The exact wording of an acknowledgement will depend on the agreement between the two parties, the details of the service being provided, and the responsibilities assigned to each party. The acknowledgement does not have to include the exact wording provided in this requirement."
    });
    // server.create('saq-answer', { saqId: saqA.id, questionId: q1282.id, type: 'NOT_APPLICABLE' });
    var q1283 = server.create('saq-question', {
      saqId: saqA.id,
      section: section12,
      code: '12.8.3',
      text: "Is there an established process for engaging service providers, including proper due diligence prior to engagement?"
    });
    var q1284 = server.create('saq-question', {
      saqId: saqA.id,
      section: section12,
      code: '12.8.4',
      text: "Is a program maintained to monitor service providers PCI DSS compliance status at least annually?"
    });
    var q1285 = server.create('saq-question', {
      saqId: saqA.id,
      section: section12,
      code: '12.8.5',
      text: "Is information maintained about which PCI DSS requirements are managed by each service provider, and which are managed by the entity?"
    });
    server.create('dashboard');
    server.create('rss');
    var scanIp1 = server.create('scan-ip', {
      id: 1
    });
    var scanIp2 = server.create('scan-ip', {
      id: 2
    });
    var scanIp3 = server.create('scan-ip', {
      id: 3
    });
    var scanIp4 = server.create('scan-ip', {
      id: 4
    });
    var scanIp5 = server.create('scan-ip', {
      id: 5
    });
    var scanIp6 = server.create('scan-ip', {
      id: 6
    });
    var scanIp7 = server.create('scan-ip', {
      id: 7
    });
    var scanIp8 = server.create('scan-ip', {
      id: 8
    });
    var scanIp9 = server.create('scan-ip', {
      id: 9
    });
    var assetIp1 = server.create('asset-ip', {
      entity: entity2,
      pciPassed: false
    });
    var assetIp2 = server.create('asset-ip', {
      entity: entity2,
      pciPassed: true
    });
    var assetIp3 = server.create('asset-ip', {
      entity: entity2,
      pciPassed: false
    });
    var assetIp4 = server.create('asset-ip', {
      entity: entity1,
      pciPassed: false
    });
    server.create('vulnerability', {
      assetIp: assetIp1,
      scanIp: scanIp1
    });
    server.create('vulnerability', {
      assetIp: assetIp1,
      scanIp: scanIp1
    });
    server.create('vulnerability', {
      assetIp: assetIp1,
      scanIp: scanIp1
    });
    server.create('vulnerability', {
      assetIp: assetIp1,
      scanIp: scanIp4
    });
    server.create('vulnerability', {
      assetIp: assetIp4,
      scanIp: scanIp9
    });

    // server.create('vulnerability', {assetIp: assetIp2, scanIp: scanIp4});

    var scan1 = server.create('scan', {
      entity: entity2,
      scanIps: [scanIp1, scanIp2, scanIp3]
    });
    var scan2 = server.create('scan', {
      entity: entity2,
      scanIps: [scanIp4]
    });
    var scan3 = server.create('scan', {
      entity: entity2,
      scanIps: [scanIp5, scanIp6, scanIp7, scanIp8]
    });
    var scan4 = server.create('scan', {
      entity: entity1,
      scanIps: [scanIp9]
    });
    var files = server.createList('file', 50);
  }
});