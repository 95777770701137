define("zerorisk-frontend/helpers/pluralize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.pluralize = pluralize;
  function pluralize(params, hash) {
    var number = params[0],
      single = params[1];
    var out = hash.noNumber ? '' : number + ' ';
    (true && !(single) && Ember.assert('pluralize requires a singular string (s)', single));
    var plural = params[2] || single + 's';
    out += number === 1 ? single : plural;
    return out;
  }
  var _default = Ember.Helper.helper(pluralize);
  _exports.default = _default;
});