define("zerorisk-frontend/mirage/config", ["exports", "ember-cli-mirage", "ember-file-upload/mirage", "faker"], function (_exports, _emberCliMirage, _mirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    //this.urlPrefix = 'http://localhost:8080';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = 'api/v2';    // make this `api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    this.post('/api/v2/users/sign_in', function () {
      // let now = new Date();
      // let cookieExpiration = new Date(now.getTime() + (24 * 3600 * 1000));
      // document.cookie = `JSESSIONID=DF3ED2FD7496A15FF0FACF1F70D61FF4; domain=localhost; path=/; expires=${cookieExpiration.toUTCString()};`;

      return {
        result: {
          "token": 'DF3ED2FD7496A15FF0FACF1F70D61FF4',
          "role": 'ROLE_MERCHANT_ENTITY_ADMIN',
          "firstName": 'Luca',
          "lastName": "D'Alessandro",
          "userId": 2
        },
        success: true
      };
    });
    this.get('/api/v2/shared/notifications');
    this.get('/api/v2/merchant/dashboard');
    this.get('/api/v2/shared/rss', function (schema, request) {
      var requestedUrl = request.queryParams.feedUrl;
      var rss = schema.db.rsses[0].news;
      var advantio = "https://blog.advantio.com/rss.xml";
      var mastercard = "http://newsroom.mastercard.com/feed/?post_type";
      var pcisecuritystandards = "https://www.pcisecuritystandards.org/news_events/rss.php?type";
      var visaeurope = "https://www.visaeurope.com/newsroom/rss-feeds";
      switch (requestedUrl) {
        case advantio:
          return rss.advantio;
          break;
        case mastercard:
          return rss.mastercard;
          break;
        case pcisecuritystandards:
          return rss.pcisecuritystandards;
          break;
        case visaeurope:
          return rss.visaeurope;
          break;
      }
    });

    // ASSET-IPS

    this.get('/api/v2/merchant/asv/assetIps', function (schema) {
      var assetIp = schema.assetIps.all();
      assetIp.models.forEach(function (ip) {
        var fail = false;
        ip.vulnerabilities.models.map(function (vuln) {
          if (vuln.pciFlag) {
            fail = true;
          }
        });
        if (fail) {
          ip.pciPassed = false;
        }
      });
      return assetIp;
    });
    this.get('/api/v2/merchant/asv/assetIps/:id', function (schema, request) {
      var id = request.params.id;
      var assetIp = schema.assetIps.find(id);
      var fail = false;
      assetIp.vulnerabilities.models.map(function (vuln) {
        if (vuln.pciFlag) {
          fail = true;
        }
      });
      if (fail) {
        assetIp.pciPassed = false;
      }
      return assetIp;
    });
    this.post('/api/v2/merchant/asv/assetIps', function (schema, request) {
      var attr = this.normalizedRequestAttrs();
      var entity = schema.entities.find(attr.entity);
      attr.entity = entity;
      attr.pciPassed = null;
      return schema.assetIps.create(attr);
    });
    this.put('/api/v2/merchant/asv/assetIps/:id', function (schema, request) {
      var id = request.params.id;
      var data = JSON.parse(request.requestBody);
      schema.assetIps.find(id).update(data);
    });
    this.del('/api/v2/merchant/asv/assetIps/:id');
    this.post('/api/v2/merchant/asv/assetIps/resolve', function () {
      return {
        result: {
          domain: "231.235.472.13"
        },
        success: true
      };
    });

    // VULNERABILITIES

    this.get('/api/v2/merchant/asv/vulnerabilities/:id');
    this.get('/api/v2/merchant/asv/vulnerabilities');

    // SCANS

    this.get('/api/v2/merchant/asv/scans');
    this.get('/api/v2/merchant/asv/scans/:id');
    this.post('/api/v2/merchant/asv/scans', function (schema, request) {
      var data = JSON.parse(request.requestBody);
      var newScanIps = data.scan.assetIps.map(function (assetIp) {
        var newScanIp = {
          id: assetIp,
          ipNumber: "127.0.0.1",
          domain: "host".concat(assetIp),
          originAssetIp: assetIp
        };
        return schema.scanIps.create(newScanIp);
      });
      var entity = schema.entities.find(data.scan.entity || 11);
      data.scan.scanIps = newScanIps;
      data.scan.entity = entity;
      return schema.scans.create(data.scan);
    });
    this.put('/api/v2/merchant/asv/scans/:id', function (schema, request) {
      var scan_id = request.params.id;
      var data = JSON.parse(request.requestBody);
      return schema.scans.find(scan_id).update(data);
    });
    this.del('/api/v2/merchant/asv/scans/:id', function (schema, request) {
      var scan_id = request.params.id;
      return schema.scans.find(scan_id).destroy();
    });

    // this.passthrough('/api/v1/users/sign_in');

    // this.passthrough('/Wizard/**');
    // this.passthrough('/api/v2/Wizard/**');

    // this.passthrough('/logout');

    this.get('/api/v2/shared/entities');
    this.get('/api/v2/shared/entities/:id');
    this.get('/api/v2/shared/users/:id');
    // this.get('/api/v2/shared/users', 'users', { coalesce: true });
    // this.passthrough('/api/v2/shared/users/**');

    this.get('/api/v2/merchant/saqs');
    this.get('/api/v2/merchant/saqs/:id');
    this.put('/api/v2/merchant/saqs/:id');

    // this.passthrough('/api/v2/merchant/saqs/**');
    // this.passthrough('/api/v2/merchant/saqQuestions/**');
    // this.passthrough('/api/v2/merchant/saqAnswers/**');

    this.get('/api/v2/merchant/saqQuestions', 'saqQuestions', {
      coalesce: true
    });
    this.get('/api/v2/merchant/saqAnswers', 'saqAnswers', {
      coalesce: true
    });
    this.get('/api/v2/merchant/saqAnswers/:id', 'saqAnswer');
    this.put('/api/v2/merchant/saqAnswers/:id', function (schema, request) {
      var payload = JSON.parse(request.requestBody);
      payload.saqAnswer.questionId = payload.saqAnswer.question;
      payload.saqAnswer.saqId = payload.saqAnswer.saq;
      delete payload.saqAnswer.question;
      delete payload.saqAnswer.saq;
      payload = payload.saqAnswer;
      schema.db.saqAnswers.update(request.params.id, payload);
      return schema.saqAnswers.find(request.params.id);
    });
    this.post('/api/v2/merchant/saqAnswers', function (schema, request) {
      var payload = JSON.parse(request.requestBody);
      payload.saqAnswer.questionId = payload.saqAnswer.question;
      payload.saqAnswer.saqId = payload.saqAnswer.saq;
      delete payload.saqAnswer.question;
      delete payload.saqAnswer.saq;
      payload = payload.saqAnswer;
      return schema.saqAnswers.create(payload);
      // return schema.saqAnswers.find(request.params.id);
    });

    /*=====================================
    =            Files section            =
    =====================================*/
    this.post('/api/v2/shared/files', (0, _mirage.upload)(function (schema, _ref) {
      var _ref$requestBody = _ref.requestBody,
        name = _ref$requestBody.name,
        description = _ref$requestBody.description,
        visible = _ref$requestBody.visible,
        type = _ref$requestBody.type;
      var visibleVal = visible === 'true' ? true : false;
      return schema.files.create({
        name: name,
        reference: _faker.default.random.number(),
        description: description,
        visible: visibleVal,
        type: type
      });
    }));
    this.get('/api/v2/shared/files', function (schema, request) {
      var files = schema.files.all();
      if (request.queryParams.type) {
        var type = request.queryParams.type;
        files.models = files.models.filter(function (file) {
          return file.type === type;
        });
      }
      if (request.queryParams.visible) {
        var visible = request.queryParams.visible === 'true' ? true : false;
        files.models = files.models.filter(function (file) {
          return file.visible === visible;
        });
      }
      if (request.queryParams.searchQuery) {
        var searchQuery = request.queryParams.searchQuery;
        files.models = files.models.filter(function (file) {
          return file.name.indexOf(searchQuery) !== -1 || file.description.indexOf(searchQuery) !== -1;
        });
      }
      var total = files.models.length;
      var perPage = parseInt(request.queryParams.perPage);
      var totalPages = Math.ceil(total / perPage);
      var page = parseInt(request.queryParams.page);
      --page;
      files.models = files.models.slice(page * perPage, (page + 1) * perPage);
      var json = this.serialize(files);
      // json.result.files = []; empty result
      json.result.meta = {
        page: page,
        perPage: perPage,
        totalPages: totalPages,
        total: total
      };
      return json;
    });
    this.get('/api/v2/shared/files/:id');
    this.put('/api/v2/shared/files/:id');
    /*=====  End of Files section  ======*/
  }

  /*
  You can optionally export a config that is only loaded during tests
  export function testConfig() {
  
  }
  */
});